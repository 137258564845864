import axios from 'axios';

import {
  APPEND_ERROR, CLOSE_PAGE,
  SEND_NICKNAME_FAIL, SEND_NICKNAME_SUCCESSFUL,
} from '../types';

// Get categories list
export const submitInstagramUsername = (userID, username) => async dispatch => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  try {
    const agent_id = await axios.post('/api/cny/registration', JSON.stringify({username: username}), config);
    const flowName = 'Microapp CNY - Instagram Display Registration';

    let res = await axios.get(`/api/flows/${flowName}`);
    const flowID = res.data._id;

    // Initializing Content
    const body = {
      'object': 'page',
      'entry': [
        {
          'messaging': [
            {
              'sender': {'id': userID},
              'postback': {'payload': `${flowID}_${agent_id.data}`},
            }],
        }],
    };
    dispatch({
      type: CLOSE_PAGE
    });
    // Posting to Gateway
    await axios.post(`/gateway/facebook`, body);
    dispatch({
      type: SEND_NICKNAME_SUCCESSFUL,
      payload: res.data,
    });
    dispatch({
      type: CLOSE_PAGE
    });

  } catch (e) {
    console.log(e);
    console.log(e.response.data);
    dispatch({
      type: APPEND_ERROR,
      payload: e.response.data.errors,
    });
  }
};
