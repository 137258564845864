import React, { Fragment, useState } from 'react'
import { connect } from 'react-redux'
import {
  searchSubcategories,
  clearSubcategories,
} from '../../actions/underwriting/category'
import {
  SearchBar,
} from 'react-weui'
//import styles
import 'weui'
import 'react-weui/build/packages/react-weui.css'
import PropTypes from 'prop-types'

const SearchbarWeUI = ({ searchSubcategories, clearSubcategories }) => {
  const [formData, setFormData] = useState({
    searchText: '',
  })

  const { searchText } = formData

  const onChange = value => {
    setFormData({ ...formData, searchText: value })
    searchSubcategories(value)
  }

  const onSubmit = () => {
    clearSubcategories()
    setFormData({ ...formData, searchText: '' })
  }

  return (
    <Fragment>
      <SearchBar
        onChange={value => onChange(value)}
        defaultValue={searchText}
        placeholder="Search Topic"
        lang={{
          cancel: 'Cancel',
        }}
        searchName="searchText"
      />
    </Fragment>

  )
}
SearchbarWeUI.propTypes = {
  searchSubcategories: PropTypes.func.isRequired,
  clearSubcategories: PropTypes.func.isRequired,
}

export default connect(null, { searchSubcategories, clearSubcategories })(
  SearchbarWeUI)