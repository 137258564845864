import axios from 'axios';

import {
  GET_CATEGORY,
  CATEGORY_ERROR,
  GET_SUBCATEGORY,
  SUBCATEGORY_ERROR,
  GET_MEDICAL_CONDITION,
  MEDICAL_CONDITION_ERROR,
  SEND_SELECTION_SUCCESSFUL,
  SEND_SELECTION_FAIL,
  CLEAR_SEARCH_RESULTS,
  SHOW_SEARCH_RESULTS,
  SEARCH_ERROR,
} from '../types';

// Get categories list
export const getCategories = () => async dispatch => {
  try {
    const res = await axios.get('/api/underwriting');
    dispatch({
      type: GET_CATEGORY,
      payload: res.data,
    });
  } catch (e) {
    console.log(e);
    dispatch({
      type: CATEGORY_ERROR,
      payload: {msg: e.statusText, status: e.status},
    });
  }
};

// Get selected category
export const getSubcategories = category => async dispatch => {
  try {
    const encodedCategory = encodeURIComponent(category);
    const res = await axios.get(`/api/underwriting/${encodedCategory}`);
    // For OTHER UNDERWRITING GUIDE, dispatch with GET_MEDICAL CONDITION
    // OTHER UNDERWRITING GUIDE doesnt have any subcategory, therefore it wil go straight to medical condition
    if (category == 'OTHER UNDERWRITING GUIDE'){
      dispatch({
        type: GET_MEDICAL_CONDITION,
        payload: res.data,
        clickedCat: category
      })
    }
    else{
      dispatch({
        type: GET_SUBCATEGORY,
        payload: res.data,
        clickedCat: category
      });
    }
  } catch (e) {
    console.log(2);
    dispatch({
      type: SUBCATEGORY_ERROR,
      payload: {msg: e.statusText, status: e.status},
    });
  }
};

// post data to gateway
export const postSubcategory = (userID, subcategory) => async dispatch => {
//  const flowName = 'Microapp Underwriting - Guide';

  try {
    // Getting required information
//    let res = await axios.get(`/api/flows/${flowName}`);
//    const flowID = res.data._id;

    // Hardcode flowID
    const flowID = "5ddddfc1a38e6703d0f523b4"

    // Initializing Content
    const body = {
      'object': 'page',
      'entry': [
        {
          'messaging': [
            {
              'sender': {'id': userID},
              'postback': {'payload': `FL${flowID}_${subcategory}_0`},
            }],
        }],
    };

    // Posting to Gateway
    await axios.post(`/gateway/facebook`, body);
    dispatch({
      type: SEND_SELECTION_SUCCESSFUL,
    });
  } catch (e) {
    dispatch({
      type: SEND_SELECTION_FAIL,
      payload: {msg: e.statusText, status: e.status},
    });
  }
};

export const getMedicalCondition = (category, subcategory) => async dispatch => {
  // Get medical data based on the category and subcategory chosen
  try {
    const encodedCategory = encodeURIComponent(category)
    const encodedSubcategory = encodeURIComponent(subcategory);
    const res = await axios.get(`/api/underwriting/${encodedCategory}/${encodedSubcategory}`);
    dispatch({
      type: GET_MEDICAL_CONDITION,
      payload: res.data,
    });
  } catch (e) {
    console.log(2);
    dispatch({
      type: MEDICAL_CONDITION_ERROR,
      payload: {msg: e.statusText, status: e.status},
    });
  }
};

// post data to gateway
export const postMedicalCondition = (userID, medical) => async dispatch => {
//  const flowName = 'Microapp Underwriting - Guide';

  try {
    // Getting required information
//    let res = await axios.get(`/api/flows/${flowName}`);
//    const flowID = res.data._id;

    // Hardcode flowID
    const flowID = "5ddddfc1a38e6703d0f523b4"

    // Initializing Content
    const body = {
      'object': 'page',
      'entry': [
        {
          'messaging': [
            {
              'sender': {'id': userID},
              'postback': {'payload': `FL${flowID}_${medical}_0`},
            }],
        }],
    };

    // Posting to Gateway
    await axios.post(`/gateway/facebook`, body);
    dispatch({
      type: SEND_SELECTION_SUCCESSFUL,
    });
  } catch (e) {
    dispatch({
      type: SEND_SELECTION_FAIL,
      payload: {msg: e.statusText, status: e.status},
    });
  }
};

export const searchSubcategories = query => async dispatch => {
  if (!query) {
    dispatch({
      type: CLEAR_SEARCH_RESULTS,
    });
    return;
  }
  try {
    const encodedQuery = encodeURIComponent(query);
    const res = await axios.get(`/api/underwriting/search/${encodedQuery}`);
    const data = res.data.map(condition => condition['Medical Conditions']);
    dispatch({
      type: SHOW_SEARCH_RESULTS,
      payload: {query: query, results: data},
    });
  } catch (e) {
    dispatch({
      type: SEARCH_ERROR,
      payload: {msg: e.statusText, status: e.status},
    });
  }
};

export const clearSubcategories = () => async dispatch => {
  dispatch({
    type: CLEAR_SEARCH_RESULTS,
  });
};
