import {
  GET_CATEGORY,
  CATEGORY_ERROR,
  GET_SUBCATEGORY,
  SUBCATEGORY_ERROR,
  GET_MEDICAL_CONDITION,
  MEDICAL_CONDITION_ERROR,
  SEARCH_SUBCATEGORY,
  CLEAR_SEARCH_RESULTS,
  SHOW_SEARCH_RESULTS,
} from '../actions/types';

const initialState = {
  categories: null,
  loading: true,
  error: {},
  sendToGateway: false,
  query: null,
  results: [],
  searchBar: true,
  categorySearch: false,
  clickedCategory: null
};

export default function(state = initialState, action) {
  const {type, payload, clickedCat} = action;
  switch (type) {
    case GET_CATEGORY:
      return {
        ...state,
        categories: payload,
        loading: false,
        sendToGateway: false,
        searchBar: true,
        categorySearch: false, // Return to initial state
        clickedCategory: null
      };
    case CATEGORY_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    case GET_SUBCATEGORY:
      return {
        ...state,
        categories: payload,
        loading: false,
        sendToGateway: false,
        searchBar: false,
        categorySearch: true,
        categoryClicked: clickedCat
      };
    case SUBCATEGORY_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    case GET_MEDICAL_CONDITION:
      return {
        ...state,
        categories: payload,
        loading: false,
        sendToGateway: true,
        searchBar: false,
        categorySearch: true,
        categoryClicked: clickedCat,
      };
    case MEDICAL_CONDITION_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    case SHOW_SEARCH_RESULTS:
      return {
        ...state,
        query: payload.query,
        results: payload.results,
        sendToGateway: true,
        loading: false,
      };
    case CLEAR_SEARCH_RESULTS:
      return {
        ...state,
        query: null,
        results: [],
        sendToGateway: false,
      };
    default:
      return state;
  }
}
