export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT = 'LOGOUT';
export const GET_CATEGORY = 'GET_CATEGORY';
export const CATEGORY_ERROR = 'CATEGORY_ERROR';
export const GET_SUBCATEGORY = 'GET_SUBCATEGORY';
export const SUBCATEGORY_ERROR = 'SUBCATEGORY_ERROR';
export const GET_MEDICAL_CONDITION = 'GET_MEDICAL_CONDITION';
export const MEDICAL_CONDITION_ERROR = 'MEDICAL_CONDITION_ERROR';
export const SEND_SELECTION_SUCCESSFUL = 'SEND_SELECTION_SUCCESSFUL';
export const SEND_SELECTION_FAIL = 'SEND_SELECTION_FAIL';
export const FB_PSID_LOGIN_SUCCESS = 'FB_PSID_LOGIN_SUCCESS';
export const FB_PSID_LOGIN_FAIL = 'FB_PSID_LOGIN_FAIL';
export const CHANGE_PAGE_STATE = 'CHANGE_PAGE_STATE';
export const SHOW_SEARCH_RESULTS = 'SHOW_SEARCH_RESULTS';
export const CLEAR_SEARCH_RESULTS = 'CLEAR_SEARCH_RESULTS';
export const SEARCH_ERROR = 'SEARCH_ERROR';
export const SEARCH_SUBCATEGORY = 'SEARCH_SUBCATEGORY';
export const SEND_NICKNAME_SUCCESSFUL = 'SEND_NICKNAME_SUCCESSFUL';
export const SEND_NICKNAME_FAIL = 'SEND_NICKNAME_FAIL';
export const APPEND_ERROR = 'APPEND_ERROR';
export const CLOSE_PAGE = 'CLOSE_PAGE';
