import React, {Fragment, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import './Registration.css';
import {
  Agreement,
  Button,
  ButtonArea,
  CellsTitle,
  CellBody,
  CellHeader,
  Form,
  FormCell,
  Label,
  Input,
} from 'react-weui';
//import styles
import 'weui';
import 'react-weui/build/packages/react-weui.css';
import {submitInstagramUsername} from '../../actions/cny/registration';
import {close} from '../../actions/facebook/webview-controls';

const Registration = ({auth: {user}, page: {errors, closeHandle}, submitInstagramUsername, close}) => {
  useEffect(() => {
    if (closeHandle) {
      close();
    }
  }, [closeHandle]);
  const [formData, setFormData] = useState({username: ''});

  const {username} = formData;

  const onChange = e => {
    console.log(e.target.value);
    setFormData({...formData, [e.target.name]: e.target.value});
  };

  const onSubmit = () => {
    submitInstagramUsername(user.facebook.id, username);
  };

  const headerStyle = {
    color: 'black',
  };

  return (
      <Fragment>
        <div className="registration">
          <section className="form-container">
            <CellsTitle>Registration for RGE2020
              {
                errors.length > 0
                &&
                <div className='error'>
                  Kindly input a valid instagram username
                </div>
              }
            </CellsTitle>
            <Form>
              <FormCell>
                <CellHeader>
                  <Label style={headerStyle}><i
                      className="fab fa-instagram"/>&nbsp;&nbsp;Instagram</Label>
                </CellHeader>
                <CellBody>
                  <Input
                      id='username'
                      type="text"
                      required
                      placeholder="Enter your username"
                      value={username}
                      onChange={e => onChange(e)}
                      name="username"
                      autoComplete="username"
                  />
                </CellBody>
              </FormCell>
            </Form>
            {/*<Agreement>*/}
            &nbsp;&nbsp;By clicking "Submit", you acknowledge that you have read and &nbsp;&nbsp;agreed to the terms and conditions.
            {/*<a href="/cny/terms">RGE2020 Terms and Privacy*/}
              {/*Your agreement with us includes these Terms and Conditions of Use (“Terms”) and our Privacy Policy (together with the Mobile Terms where applicable, the “Agreements”).*/}
              {/*  &nbsp;&nbsp;I agree to the <a href="#">RGE2020 Terms and*/}
              {/*  Privacy</a>*/}
              {/*</Agreement>*/}
              <ButtonArea>
                <Button type="default"
                    //button to display toptips
                        onClick={() => onSubmit()}> Submit </Button>
              </ButtonArea>
          </section>
        </div>
      </Fragment>
);
};

Registration.propTypes = {
  close: PropTypes.func.isRequired,
  submitInstagramUsername: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  page: PropTypes.object.isRequired,
  cny: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
  cny: state.cny,
  page: state.page,
});

export default connect(mapStateToProps, {close, submitInstagramUsername})(Registration);
