import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import './Category.css';
import {
  getSubcategories,
  postSubcategory,
  getMedicalCondition,
  postMedicalCondition,
} from '../../actions/underwriting/category';
import {close} from '../../actions/facebook/webview-controls';
import {
  Cells,
  CellsTitle,
  Cell,
  CellBody,
  CellFooter,
  Page,
} from 'react-weui';
//import styles
import 'weui';
import 'react-weui/build/packages/react-weui.css';
import SearchbarWeUI from '../layout/SearchbarWeUI';

const Category = ({auth: {user}, underwriting: {query, results, categories, sendToGateway, searchBar, categorySearch, categoryClicked}, close, getSubcategories, postSubcategory, getMedicalCondition, postMedicalCondition}) => {
//  const title = sendToGateway ? 'Medical Conditions' : 'Medical Impairments – Impact on Risk Class';
  let title
  if (categorySearch){
    if (!sendToGateway){
      title = categoryClicked
    }
    else{
      if (categoryClicked == 'OTHER UNDERWRITING GUIDE'){
        // If user clicked on other underwriting guide, set it as title
        title = categoryClicked
      }
      else{
        title = 'Medical Conditions'
      }
    }
  }
  else{
    title = 'Underwriting Category'
  }
  const click = (cat) => {
    if (sendToGateway) {
      // If send to gateway is true send a payload to gateway and close the microapp
      postMedicalCondition(user.facebook.id, cat);
      close();
    } else {
      if (categorySearch){
        // If there a category has been chosen, find the medical condition with that uw category and subcategory
        getMedicalCondition(categoryClicked, cat);
      }
      else{
        // Get the list of subcatgories from the chosen category
        getSubcategories(cat);
      }
    }
  };
  const Content = ({cat}) => {
    if (cat) {
      return (
          <Cell access>
            <CellBody onClick={() => click(cat)}>
              {/*Bold first letter*/}
              <b style={{color: 'red', 'font-size': '150%'}}>{cat.substring(0, 1)}</b>{cat.substring(1)}
            </CellBody>
            {!sendToGateway && <CellFooter/>}
          </Cell>
      );
    } else {
      return (
          <Cell>
            <CellBody>
              No results found
            </CellBody>
          </Cell>
      );
    }
  };

  let displayCategories;
  if (query && results.length <= 0) {
    displayCategories = <Content/>;
  } else if (query && results.length > 0) {
    displayCategories = results.map(cat => (<Content key={cat} cat={cat}/>));
  } else {
    displayCategories = categories.map(cat => (<Content key={cat} cat={cat}/>));
  }

  return (
      <Fragment>
        <section className="category-container">
          {searchBar && <SearchbarWeUI/>}
          <div className={!searchBar && 'offset'}>
            <CellsTitle>{title}</CellsTitle>
            <Cells>
              {displayCategories}
            </Cells>
          </div>
        </section>
      </Fragment>
  );
};

Category.propTypes = {
  getSubcategories: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
  postSubcategory: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  search: PropTypes.object.isRequired,
  underwriting: PropTypes.object.isRequired,
  // subcategory: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
  underwriting: state.underwriting,
  auth: state.auth,
  search: state.search,
});

export default connect(mapStateToProps,
    {getSubcategories, postSubcategory, getMedicalCondition, postMedicalCondition, close})(Category);
