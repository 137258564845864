import {CHANGE_PAGE_STATE, APPEND_ERROR, CLOSE_PAGE} from '../actions/types';

const initialState = {
  pageName: null,
  errors: [],
  closeHandle: false,
};

export default function(state = initialState, action) {
  const {type, payload} = action;

  switch (type) {
    case CHANGE_PAGE_STATE:
      return {
        ...state,
        pageName: payload,
      };
    case APPEND_ERROR:
      return {
        ...state,
        errors: payload,
      };
    case CLOSE_PAGE:
      return {
        ...state,
        closeHandle: true,
      };

    default:
      return state;
  }
}
