import React, { Fragment } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { logout } from '../../actions/auth'

const Navbar = ({ auth: { isAuthenticated, loading }, logout, page: { pageName } }) => {
  const authLinks = (
    <ul>
      <li>
        <a onClick={logout} href="#!">
          <i className="fas fa-sign-out-alt"/>{' '}
          <span className='hide-sm'>Logout</span></a>
      </li>
    </ul>
  )

  const guestLinks = (
    <ul>
      <li><a href="#!">Developers</a></li>
      <li><Link to="/subcateg">Register</Link></li>
      <li><Link to="/login">Login</Link></li>
    </ul>
  )

  let title
  let homePageLink = '/'
  switch (pageName) {
    case 'underwriting':
      title = 'Underwriting Guide'
      homePageLink = 'underwriting'
  }

  const history = useHistory()
  let reloadRoute = () => {
    // if you just want to reload the state then push an empty route and go back, this will cause component to re-render
    history.push('/empty')
    // history.push(`/${homePageLink}`)
    history.goBack()
  }

  return (

    <nav className="navbar bg-dark">
      <a className="home-button" onClick={reloadRoute}><i className="fas fa-home home-button" /></a>
      <h1 className="title">
        {/*<Link to={`/${homePageLink}`}></Link>*/}
        {title}
      </h1>
      <div>
        &nbsp;
      </div>
      {/*{!loading &&*/}
      {/*(<Fragment>{isAuthenticated ? authLinks : guestLinks}</Fragment>)}*/}
    </nav>
  )
}

Navbar.propTypes = {
  logout: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  page: PropTypes.object.isRequired,
}
const mapStateToProps = state => ({
  auth: state.auth,
  page: state.page,
})

export default connect(mapStateToProps, { logout })(Navbar)
