import {SEND_NICKNAME_SUCCESSFUL} from '../actions/types';

const initialState = {
  closeWindow: false,
};

export default function(state = initialState, action) {
  const {type} = action;

  switch (type) {
    case SEND_NICKNAME_SUCCESSFUL:
      return {
        ...state,
        closeWindow: true
      };
    default:
      return state;
  }
}